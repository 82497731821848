<template>
  <div>
    <b-modal
      id="modal-edit-id-member"
      ref="modal-edit-id-member"
      title="แก้ไขข้อมูลบัตรประชาชน"
      no-close-on-backdrop
      hide-footer
    >
      <b-form @submit.prevent="editMember">
        <!-- ชื่อ -->
        <b-form-group label="ชื่อ EN">
          <b-form-input v-model="form.id_en_firstname" />
        </b-form-group>

        <!-- นามสกุล -->
        <b-form-group label="นามสกุล EN">
          <b-form-input v-model="form.id_en_last_name" />
        </b-form-group>

        <b-form-group label="ที่อยู่">
          <b-form-input v-model="form.id_address1" />
        </b-form-group>

        <b-form-group label="ถนน">
          <b-form-input v-model="form.id_road" />
        </b-form-group>

        <b-form-group label="แขวง / ตำบล">
          <b-form-input v-model="form.id_district" />
        </b-form-group>

        <b-form-group label="เขต / อำเภอ">
          <b-form-input v-model="form.id_amphoe" />
        </b-form-group>

        <b-form-group label="จังหวัด">
          <b-form-input v-model="form.id_province" />
        </b-form-group>
        <hr />
        <b-button variant="success" @click.prevent="EditMember">
          แก้ไขข้อมูล
        </b-button>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import {
  BModal,
  BCard,
  BFormGroup,
  BForm,
  BFormInput,
  BButton,
} from "bootstrap-vue";

export default {
  components: {
    BCard,
    BModal,
    BForm,
    BButton,
    BFormGroup,
    BFormInput,
  },
  props: ["member_id"],
  data() {
    return {
      form: {},
    };
  },
  created() {
    this.LoadMember();
  },
  methods: {
    LoadMember() {
      this.$http({
        method: "GET",
        url: `/member/view/` + this.member_id,
        data: this.form,
      })
        .then((x) => {
          this.form = x.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    EditMember() {
      this.$http({
        method: "PUT",
        url: `/member/${this.member_id}`,
        data: this.form,
      })
        .then((x) => {
          this.$refs["modal-edit-id-member"].hide();
          this.showToast("success", "CheckIcon");
          this.LoadMember();
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
